import axios from 'axios'

export default function stripeCheckout(checkoutParams) {
  const { compositionLink, compositionName, customerEmail, priceId } =
    checkoutParams

  const config = {
    method: 'post',
    url: '/.netlify/functions/stripe-checkout',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({
      compositionLink,
      compositionName,
      customerEmail,
      priceId
    })
  }

  return axios(config)
}
