import { Nav } from '../components/Nav.js'
import { Footer } from '../components/Footer'

const NotFoundPage = () => {
  return (
    <div className="page-wrapper">
      <Nav />
      <section id="#">
        <div className="section-wrapper flex-row">
          <div className="width-100 stacked not-found-stack">
            <h1 className="not-found-title">404</h1>
            <div className="not-found-description">
              <span>Sorry but the page you are looking for doesn't exist!</span>
              <span>Click the button below to go back to the home page.</span>
            </div>
            <a href="/home" className="button button-filled">
              Home
            </a>
            <br />
          </div>
        </div>
      </section>
      <section className="blue-section">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="svg-wave-top white-fill"
        >
          <path
            fillOpacity="1"
            d="M0,64L48,80C96,96,192,128,288,122.7C384,117,480,75,576,69.3C672,64,768,96,864,117.3C960,139,1056,149,1152,160C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="section-wrapper section-padding-top">
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <Footer />
        </div>
      </section>
    </div>
  )
}

export default NotFoundPage
