import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PianoIcon from '../assets/piano.svg'
import BarsIcon from '../assets/bars.svg'
import CompositionsIcon from '../assets/compositions.svg'
import ScheduleIcon from '../assets/schedule.svg'
import HomeIcon from '../assets/home.svg'

const INITIAL_SHADOW = '0 1px 3px rgba(0, 0, 0, 0)'

export const Nav = props => {
  const { empty } = props
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [boxShadow, setBoxShadow] = useState(INITIAL_SHADOW)

  const location = useLocation()

  const handleShow = () => {
    if (show) {
      // slide in transition
      setOpen(false)
      setTimeout(() => setShow(false), 400)
    } else {
      // slide out transition
      setShow(true)
      setTimeout(() => setOpen(true), 1)
    }
  }

  const listenScrollEvent = _event => {
    if (window.scrollY > 0) {
      setBoxShadow('0 1px 3px rgba(0, 0, 0, 0.25)')
    } else {
      setBoxShadow(INITIAL_SHADOW)
    }
  }

  useEffect(() => window.addEventListener('scroll', listenScrollEvent), [])

  const currentPath = `${location.pathname}${location.hash}`

  return (
    <>
      <div className="nav" style={{ boxShadow }}>
        <a href="/home" className="no-link-styles">
          <div className="nav-logo">
            <img className="nav-logo-image" src={PianoIcon} />
            <div className="nav-logo-text-container">
              <span className="nav-logo-text">Kalynn's</span>
              <span className="nav-logo-text text-small">Compositions</span>
            </div>
          </div>
        </a>
        {!empty && (
          <div className="nav-links">
            <a
              href="/home#"
              className={`link-active-${currentPath === '/home'}`}
            >
              Home
            </a>
            <a
              href="/compositions"
              className={`link-active-${currentPath === '/compositions'}`}
            >
              Compositions
            </a>
            <a
              href="/lessons"
              className={`link-active-${currentPath === '/lessons'}`}
            >
              Lessons
            </a>
          </div>
        )}
        <div className="nav-bars" onClick={handleShow}>
          <img className="nav-bars-image" src={BarsIcon} />
        </div>
      </div>
      {show && (
        <>
          <div
            className={`side-nav-back side-nav-back-open-${open}`}
            onClick={handleShow}
          />
          <div className={`side-nav side-nav-open-${open}`}>
            <div className="nav-logo">
              <img className="nav-logo-image" src={PianoIcon} />
              <div className="nav-logo-text-container">
                <span className="nav-logo-text">Kalynn's</span>
                <span className="nav-logo-text text-small">Compositions</span>
              </div>
            </div>
            <hr />
            {!empty && (
              <div className="side-nav-links">
                <a href="/home#">
                  <img src={HomeIcon} />
                  <span>Home</span>
                </a>
                <a href="/compositions">
                  <img src={CompositionsIcon} />
                  <span>Compositions</span>
                </a>
                <a href="/lessons">
                  <img src={ScheduleIcon} />
                  <span>Lessons</span>
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
