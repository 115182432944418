export const PricingTable = () => (
  <div className="price-table">
    <div className="columns">
      <ul className="price">
        <li className="header">Package (4 Lessons)</li>
        <li className="grey">$200 ($50 / hour)</li>
        <li>All Levels</li>
        <li>Learn about theory or composition if desired</li>
      </ul>
    </div>

    <div className="columns">
      <ul className="price">
        <li className="header">One Lesson</li>
        <li className="grey">$60 / hour</li>
        <li>All Levels</li>
        <li>Learn about theory or composition if desired</li>
      </ul>
    </div>
  </div>
)
