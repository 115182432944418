import PianoIcon from '../assets/piano-white.svg'

export const Footer = props => {
  const { empty } = props
  return (
    <div className="footer">
      <div className="footer-section footer-flex-grow-1">
        <div className="nav-logo">
          <img className="nav-logo-image" src={PianoIcon} />
          <div className="nav-logo-text-container">
            <span className="nav-logo-text">Kalynn's</span>
            <span className="nav-logo-text text-small">Compositions</span>
          </div>
        </div>
      </div>
      <div className="footer-section footer-flex-grow-2">
        {!empty && (
          <div className="footer-links">
            <a href="/home#">Home</a>
            <a href="/lessons">Lessons</a>
            <a href="/compositions">Compositions</a>
          </div>
        )}
        <div>
          <div>kalynnscompositions@gmail.com</div>
        </div>
        <div>
          <div>© {new Date().getFullYear()} by Kalynn's Compositions.</div>
        </div>
      </div>
    </div>
  )
}
