import CartIcon from '../assets/purchase.svg'
import YoutubeIcon from '../assets/youtube-dark.svg'
import SpotifyIcon from '../assets/spotify-dark.svg'

export const toDollars = price =>
  (price / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })

export const Row = props => {
  const { label, text, className } = props
  return (
    <div
      className={`composition-row-wrapper${className ? ' ' + className : ''}`}
    >
      <div className="composition-label">{label}</div>
      <div className="composition-card-text">{text}</div>
    </div>
  )
}

export const Icon = props => {
  const { type } = props
  return (
    <img
      className="listen-on-icon"
      src={type === 'youtube' ? YoutubeIcon : SpotifyIcon}
    />
  )
}

export const Composition = props => {
  const {
    name,
    description = '--',
    images: [image],
    default_price: { id: priceId, unit_amount: price },
    metadata: {
      artist = 'Kalynn Fleischman',
      arrangement = '--',
      instruments = '--',
      pages = '--',
      pdf,
      youtube,
      spotify
    },
    setShow,
    setCheckout
  } = props

  return (
    <div className="composition-card prevent-select">
      <div className="composition-detail-wrapper">
        <img
          src={image}
          className="composition-preview"
          onClick={() => setShow(image)}
        />
        <div className="composition-info">
          <div className="composition-row-mobile">
            <Row label="Title" text={name} />
            <Row label="Artist" text={artist} />
          </div>
          <div className="composition-row-mobile">
            <Row label="Pages" text={pages} />
            <Row label="Price" text={toDollars(price)} />
            <button
              className="button composition-row-wrapper purchase-button"
              onClick={() =>
                setCheckout({
                  price,
                  priceId,
                  title: name,
                  pdf,
                  image
                })
              }
            >
              <div className="composition-cart">
                <img className="composition-cart-icon" src={CartIcon} />
                <span>Purchase</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="composition-row-mobile composition-force-row">
        <Row label="Arrangement" text={arrangement} />
        <Row label="Instruments" text={instruments} />
      </div>
      <Row className="wrapper-unset" label="Description" text={description} />
      {(youtube || spotify) && (
        <Row
          className="wrapper-unset"
          label="Listen On"
          text={
            <div className="composition-listen-on">
              {youtube && (
                <a
                  className="listen-on-link listen-on-wrapper"
                  href={youtube}
                  target="_blank"
                >
                  {<Icon type="youtube" />} YouTube
                </a>
              )}
              {spotify && (
                <a
                  className="listen-on-link listen-on-wrapper"
                  href={spotify}
                  target="_blank"
                >
                  {<Icon type="spotify" />} Spotify
                </a>
              )}
            </div>
          }
        />
      )}
    </div>
  )
}
