import JasonImage from '../assets/jason.jpeg'
import BrianImage from '../assets/brian.jpeg'

const testimonials = klass => [
  <figure key="testimonial-2" className={`testimonial${klass}`}>
    <blockquote>
      I'm incredibly grateful for the opportunity to learn from Kalynn. His
      expertise as a music teacher shines through in every lesson. Learning
      piano and music composition can be challenging, but Kalynn's patient and
      encouraging nature has made the journey enjoyable. His ability to explain
      intricate musical concepts in a simple and relatable manner is a testament
      to his teaching skills. If you're looking for a dedicated teacher who will
      nurture your musical talents, I wholeheartedly recommend Kalynn.
      <div className="arrow"></div>
    </blockquote>
    <div className="testimonial-image">
      <img src={BrianImage} />
    </div>
    <div className="author">
      <h5>Brian</h5>
      <span>student since 2017</span>
    </div>
  </figure>,
  <figure key="testimonial-1" className={`testimonial${klass}`}>
    <blockquote>
      Having Kalynn as my music teacher has been a transformative experience.
      His passion for music is evident in every interaction, and his teaching
      approach is a perfect blend of professionalism and warmth. With Kalynn's
      guidance, I've not only developed my piano skills but also discovered a
      newfound interest in composing music. His patience, coupled with his
      ability to make learning enjoyable, sets him apart as an exceptional
      teacher. If you're serious about enhancing your musical abilities, Kalynn
      is the ideal mentor.
      <div className="arrow"></div>
    </blockquote>
    <div className="testimonial-image">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample27.jpg" />
    </div>
    <div className="author">
      <h5>Anonymous</h5>
      <span>student since 2020</span>
    </div>
  </figure>,
  <figure key="testimonial-0" className={`testimonial${klass}`}>
    <blockquote>
      Kalynn is an exellent teacher who has a passion for music; he has helped
      me in both learning piano and learning how to compose my own music. He is
      patient, engaging and also has a great sense of humor which is needed at
      times especially when a piece is frustrating. I would recommend him to
      anyone who is eager to learn and grow.
      <div className="arrow"></div>
    </blockquote>
    <div className="testimonial-image">
      <img src={JasonImage} />
    </div>
    <div className="author">
      <h5>Jason Mollerup</h5>
      <span>student since 2022</span>
    </div>
  </figure>
]

const carousel = () => <></>

export const Testimonials = () => {
  return (
    <>
      <div className="testimonials">{testimonials('')}</div>
      {/* {carousel()} */}
    </>
  )
}
