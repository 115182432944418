import { useEffect, useState } from 'react'
import { Nav } from '../components/Nav.js'
import { Footer } from '../components/Footer'
import SpotifyIcon from '../assets/spotify.svg'
import YoutubeIcon from '../assets/youtube.svg'
import { toDollars, Row, Composition } from '../components/Composition.js'
import { useSpringCarousel } from 'react-spring-carousel'
import PrevIcon from '../assets/prev.svg'
import NextIcon from '../assets/next.svg'
import CloseIcon from '../assets/close.svg'
import stripeCheckout from '../requests/stripe-checkout.js'
import fetchProducts from '../requests/fetch-products.js'

const EMAIL_REGEX = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/

const Compositions = () => {
  const [compositions, setCompositions] = useState([])
  const [active, setActive] = useState(0)
  const [selectedComposition, setSelectedComposition] = useState(null)
  const [checkoutComposition, setCheckoutComposition] = useState(null)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(null)

  // modal usage
  const [open, setOpen] = useState(false)

  const handleOpen = callback => {
    if (open) {
      // slide in transition
      setOpen(false)
      setTimeout(() => callback(), 400)
    } else {
      // slide out transition
      callback()
      setTimeout(() => setOpen(true), 1)
    }
  }

  useEffect(() => {
    fetchProducts()
      .then(response => setCompositions(response.data.products))
      .catch(error => console.log(error))
  }, [])

  const {
    carouselFragment,
    slideToItem,
    slideToPrevItem,
    slideToNextItem,
    useListenToCustomEvent
  } = useSpringCarousel({
    itemsPerSlide:
      compositions.length === 0
        ? 1
        : compositions.length < 3
        ? compositions.length
        : 3,
    withLoop: true,
    items:
      compositions.length === 0
        ? [<div>Loading</div>]
        : compositions.map((composition, index) => ({
            id: index,
            renderItem: (
              <Composition
                {...composition}
                setShow={image => {
                  handleOpen(() => setSelectedComposition(image))
                }}
                setCheckout={composition => {
                  handleOpen(() => setCheckoutComposition(composition))
                }}
              />
            )
          }))
  })

  useListenToCustomEvent(event => {
    if (event.eventName === 'onSlideChange') {
      setActive(event.currentItem.index)
    }
  })

  const slidePrev = () => {
    setActive(active === 0 ? compositions.length - 1 : active - 1)
    slideToPrevItem()
  }

  const slideNext = () => {
    setActive(active === compositions.length - 1 ? 0 : active + 1)
    slideToNextItem()
  }

  const handleCheckout = async () => {
    setLoading(true)
    stripeCheckout({
      compositionLink: checkoutComposition.pdf,
      compositionName: checkoutComposition.title,
      customerEmail: email,
      priceId: checkoutComposition.priceId
    })
      .then(res => {
        window.location = res.data.session.url
      })
      .catch(err => {
        let alert =
          'There was an issue checking out. Please contact kalynnscompositions@gmail.com for further assistance.'
        window.alert(alert)
      })
  }

  return (
    <>
      {selectedComposition && (
        <div
          className={`composition-full-page composition-open-${open}`}
          onClick={() => handleOpen(() => setSelectedComposition(null))}
        >
          <img
            src={selectedComposition}
            className={`composition-full-page-image composition-open-${open}`}
          />
        </div>
      )}
      {checkoutComposition && (
        <div
          className={`composition-full-page no-pointer composition-open-${open}`}
        >
          <div className="checkout-modal">
            <h2 className="modal-header h2-no-margin-top h2-margin-bottom">
              Purchase Confirmation
              <img
                className="close-modal"
                src={CloseIcon}
                onClick={() => handleOpen(() => setCheckoutComposition(null))}
              />
            </h2>
            <div className="composition-detail-wrapper purchase-composition-detail-wrapper">
              <img
                src={checkoutComposition.image}
                className="composition-preview no-pointer purchase-composition-preview"
              />
              <div className="composition-info">
                <Row
                  className="purchase-composition-row-wrapper"
                  label="Title"
                  text={checkoutComposition.title}
                />
                <Row
                  className="purchase-composition-row-wrapper"
                  label="Price"
                  text={toDollars(checkoutComposition.price)}
                />
              </div>
            </div>
            <div className="modal-description">
              Enter the email you would like the composition sent to.
            </div>
            <div className="form-field form-field-no-margin">
              <input
                value={email || ''}
                onChange={e => setEmail(e.target.value)}
                required
                placeholder="Email required"
              />
            </div>
            <div className="modal-detail">
              A PDF copy will be delivered by email upon payment.
            </div>
            <button
              className="button button-filled button-no-width button-hover-less"
              onClick={handleCheckout}
              disabled={!EMAIL_REGEX.test(email) || loading}
            >
              {loading ? 'Redirecting to Payment...' : 'Continue to Payment'}
            </button>
          </div>
        </div>
      )}
      <Nav />
      <section className="dark-blue-section" id="#">
        <div className="section-wrapper flex-row composition-text">
          <div className="width-50 stacked">
            <h2>Kalynn's Compositions</h2>
            <p>
              As a composer my goal is quite simply to make music that I enjoy
              and that hopefully others will enjoy as well. I wish to create art
              that expresses the full breadth of human emotion from humor to
              anger, from joy to sorrow, and so on.
              <br />
              <br />I like to challenge myself by writing for various styles and
              ensembles. By doing so, I aim to continually learn and grow as an
              artist. Thank you for visiting this page and I hope that my music
              will prove worthy of patronage.
            </p>
          </div>
          <div className="width-50 stacked stacked-row">
            <div className="playlist">
              <div className="playlist-header">
                <h3 className="composition-header-text">
                  Compositions Playlist
                </h3>
                <div className="playlist-icons">
                  <a
                    className="link-icon"
                    href="https://www.youtube.com/@kalynnscompositions"
                    target="_blank"
                  >
                    <img src={YoutubeIcon} />
                  </a>
                  <a
                    className="link-icon"
                    href="https://open.spotify.com/artist/7LxCL6hx7Q9FNlveBnUC24?si=yuWTqGVwQjSMCOjB5v3j3g"
                    target="_blank"
                  >
                    <img src={SpotifyIcon} />
                  </a>
                </div>
              </div>
              <iframe
                className="iframe-playlist"
                src="https://www.youtube.com/embed/videoseries?list=PL4m4-vcCOUV519X7VTa2qT7Zk1-SMUZuU"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                frameBorder="0"
              ></iframe>
            </div>
            <br />
          </div>
        </div>
        <br />
      </section>
      <section>
        <div className="section-wrapper">
          <div className="width-100 stacked section-no-padding-bottom">
            <h2>Compositions for Purchase</h2>

            <div className="featured-compositions">
              {(!compositions || compositions.length === 0) && (
                <div className="compositions-loading">Loading...</div>
              )}
              {carouselFragment}
            </div>
            <div className="composition-carousel-footer">
              {compositions.length > 0 && (
                <>
                  <button
                    className="button-filled button-circle"
                    onClick={slidePrev}
                  >
                    <img src={PrevIcon} />
                  </button>
                  {compositions.map((_composition, index) => {
                    return (
                      <div
                        key={`dot-${index}`}
                        className="composition-dot-wrapper"
                        onClick={() => {
                          setActive(index)
                          slideToItem(index)
                        }}
                      >
                        <div
                          className={`composition-dot composition-dot-active-${
                            index === active
                          }`}
                        />
                        <div
                          className={`composition-dot-num composition-dot-num-active-${
                            index === active
                          }`}
                        >
                          {index + 1}
                        </div>
                      </div>
                    )
                  })}
                  <button
                    className="button-filled button-circle"
                    onClick={slideNext}
                  >
                    <img src={NextIcon} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="blue-section">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="svg-wave-top white-fill"
        >
          <path
            fillOpacity="1"
            d="M0,64L48,80C96,96,192,128,288,122.7C384,117,480,75,576,69.3C672,64,768,96,864,117.3C960,139,1056,149,1152,160C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="section-wrapper section-padding-top">
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <Footer />
        </div>
      </section>
    </>
  )
}

export default Compositions
