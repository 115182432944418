import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import NotFoundPage from './pages/NotFoundPage'
import Compositions from './pages/Compositions'
import Lessons from './pages/Lessons'
import PurchaseSuccess from './pages/PurchaseSuccess'
import PurchaseCanceled from './pages/PurchaseCanceled'
import './App.css'
import './contact-form.css'
import './pricing.css'
import './testimonials.css'

import UnderConstruction from './pages/UnderConstruction'

function App() {
  return (
    <BrowserRouter>
      {/* <Routes>
        <Route path="/*" element={<UnderConstruction />} />
      </Routes> */}
      <Routes>
        <Route path="/*" element={<NotFoundPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/lessons" element={<Lessons />} />
        <Route path="/compositions" element={<Compositions />} />
        <Route path="/purchase-success" element={<PurchaseSuccess />} />
        <Route path="/purchase-canceled" element={<PurchaseCanceled />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
