import axios from 'axios'

export default function scheduleLesson(checkoutParams) {
  const { first, last, phone, email, message } = checkoutParams

  const config = {
    method: 'post',
    url: '/.netlify/functions/schedule-lesson',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({
      first,
      last,
      phone,
      email,
      message
    })
  }

  return axios(config)
}
