import PianoIcon from '../assets/piano.svg'
import CompositionIcon from '../assets/composition.svg'
import PricingHero from '../assets/pricing-hero.jpg'
import { ContactForm } from '../components/ContactForm.js'
import { Nav } from '../components/Nav.js'
import { PricingTable } from '../components/PricingTable.js'
import { Testimonials } from '../components/Testimonials.js'
import { Footer } from '../components/Footer'

const Home = () => {
  return (
    <>
      <Nav />
      <section className="blue-section" id="#">
        <div className="hero-section section-wrapper no-padding-right">
          <div className="hero-text-wrapper">
            <div className="hero-text">
              <h1 className="hero-title">
                Private
                <br />
                Piano
                <br />
                Lessons
              </h1>
              <p className="hero-description">
                Lessons available for all levels
              </p>
              <div className="hero-buttons">
                <a
                  className="button button-margin-right button-filled"
                  href="#schedule"
                >
                  Book a Lesson
                </a>
                <a className="button" href="#pricing">
                  Pick a Package
                </a>
              </div>
            </div>
          </div>
          <div className="hero-image" />
        </div>
      </section>
      <section>
        <div className="section-wrapper flex-row section-padding-bottom">
          <div className="width-40 stacked above-svg-wave">
            <h2>What do I teach?</h2>
            <p>
              As a piano pedagogue, my primary goal as a teacher is to train my
              students Classically and to grow their overall understanding of
              music. Too often teachers push their students to learn pieces
              without teaching them how to practice, why certain pieces are
              performed with certain nuances, and how to approach all the
              different building blocks that go into each piece. It is essential
              for my students to learn not just how to read notes but to also
              understand the meaning behind them.
            </p>
            <a href="#schedule" className="button button-filled">
              Book a Lesson
            </a>
          </div>
          <div className="width-60 stacked stacked-row">
            <div className="image-card">
              <h3>Piano</h3>
              <div className="image-wrapper">
                <img src={PianoIcon} />
              </div>
              <p>From Beginner to Advanced</p>
            </div>
            <div className="image-card">
              <h3>Composition</h3>
              <div className="image-wrapper">
                <img src={CompositionIcon} />
              </div>
              <p>Music Theory and how to Compose</p>
            </div>
          </div>
        </div>
        <svg
          className="svg-wave-bottom dark-blue-fill"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fillOpacity="1"
            d="M0,128L48,160C96,192,192,256,288,282.7C384,309,480,299,576,288C672,277,768,267,864,256C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </section>
      <section className="dark-blue-section">
        <div className="section-wrapper flex-row">
          <div className="width-100 stacked">
            <h2 className="color-white no-line">Testimonials</h2>
            <br />
            <Testimonials />
          </div>
        </div>
        <br />
        <br />
      </section>
      <section className="gray-section" id="pricing">
        <svg
          className="svg-wave-top dark-blue-fill"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fillOpacity="1"
            d="M0,160L48,170.7C96,181,192,203,288,186.7C384,171,480,117,576,117.3C672,117,768,171,864,192C960,213,1056,203,1152,213.3C1248,224,1344,256,1392,272L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="section-wrapper flex-row">
          <div className="width-50 stacked">
            <div className="pricing-wrapper">
              <div className="pricing-header">
                <h2 className="section-svg-wave-white">Pricing</h2>
                <div className="pricing-hero">
                  <img src={PricingHero} />
                </div>
              </div>
            </div>
          </div>
          <div className="width-50 stacked pricing-variable-padding">
            <PricingTable />
            <a href="/lessons#schedule" className="button button-orange-filled">
              Book a Lesson
            </a>
          </div>
        </div>
      </section>
      <section className="blue-section" id="schedule">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="svg-wave-top gray-fill"
        >
          <path
            fillOpacity="1"
            d="M0,64L48,80C96,96,192,128,288,122.7C384,117,480,75,576,69.3C672,64,768,96,864,117.3C960,139,1056,149,1152,160C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="section-wrapper flex-row section-padding-top">
          <div className="width-70 stacked">
            <div className="pricing-flex-grow">
              <h2 className="color-white no-line">Schedule A Lesson</h2>
              <ContactForm />
            </div>
          </div>
          <div className="questions-wrapper width-30 stacked">
            <div className="questions">
              <h3>Question or Concerns? </h3>
              <p>Please contact me at kalynnscompositions@gmail.com</p>
            </div>
          </div>
        </div>
      </section>
      <section className="blue-section">
        <div className="section-wrapper">
          <Footer />
        </div>
      </section>
    </>
  )
}

export default Home
