import { Nav } from '../components/Nav.js'
import { Footer } from '../components/Footer'
import KalynnHero from '../assets/kalynn.jpeg'
import PianoIcon from '../assets/piano.svg'
import YouTubeIcon from '../assets/youtube-og.svg'
import SpotifyIcon from '../assets/spotify-og.svg'
import CompositionIcon from '../assets/composition.svg'

const AboutMe = () => {
  return (
    <div className="page-wrapper">
      <Nav />
      <section className="gray-section hero-section-home">
        <div className="hero-section-home-inner-wrapper">
          <div className="section-wrapper ">
            <div className="width-50 stacked">
              <h1 className="hero-title hero-title-home">Discover New Music</h1>
              <p className="hero-description">
                Compositions ranging from{' '}
                <i>
                  <b>Piano Solos</b>
                </i>{' '}
                to{' '}
                <i>
                  <b>Full Orchestras</b>
                </i>
              </p>
              <div className="hero-buttons">
                <a
                  href="/compositions"
                  className="button button-margin-right button-filled"
                >
                  View Compositions
                </a>
                <a href="/lessons" className="button">
                  Learn About Lessons
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="#">
        <div className="section-wrapper flex-row">
          <div className="width-50 stacked">
            <h2>Kalynn's Compositions</h2>
            <p>
              Kalynn Fleischman is a composer and music teacher who's been
              writing music since 2011 and teaching music since 2015. He has
              participated in numerous composition competitions and his works
              have been performed in both Europe and the United States. He holds
              a Bachelor's Degree in Music with Emphasis in Piano Performance
              from Grand Canyon University (GCU). Kalynn graduated Summa Cum
              Laude and earned GCU's highest academic honor, the Ray-Maben
              award. He has received instruction from notable instructors such
              as Dr. Mark Broder (Music Theory) and Dr. Jelena Vladikovic (Piano
              Performance and Piano Pedagogy) while pursuing his undergraduate
              degree.
            </p>
            <p>
              As a piano pedagogue, his primary goal is to train students
              Classically and to grow their overall understanding of music. Too
              often do teachers push students to learn pieces without teaching
              them how to practice, why certain pieces are performed with
              certain nuances, and how to approach all the different building
              blocks that go into each piece. It is essential for students to
              learn not just how to read notes but to also understand the
              meaning behind them.
            </p>
            <br />
          </div>
          <div className="width-50 stacked">
            <div className="pricing-wrapper">
              <div className="pricing-header">
                <div className="pricing-hero">
                  <img src={KalynnHero} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="section-wrapper flex-row">
          <div className="width-60 stacked stacked-row">
            <a className="card-link" href="/compositions">
              <div className="image-card">
                <h3>Purchase Compositions</h3>
                <div className="image-wrapper">
                  <img src={PianoIcon} />
                </div>
              </div>
            </a>
            <a className="card-link" href="/lessons">
              <div className="image-card">
                <h3>Learn About Lessons</h3>
                <div className="image-wrapper">
                  <img src={CompositionIcon} />
                </div>
              </div>
            </a>
            <a
              className="card-link"
              href="https://www.youtube.com/@kalynnscompositions"
              target="_blank"
            >
              <div className="image-card">
                <h3>Listen on YouTube</h3>
                <div className="image-wrapper image-wrapper-red">
                  <img className="icon-shadow" src={YouTubeIcon} />
                </div>
              </div>
            </a>
            <a
              className="card-link"
              href="https://open.spotify.com/artist/7LxCL6hx7Q9FNlveBnUC24?si=yuWTqGVwQjSMCOjB5v3j3g"
              target="_blank"
            >
              <div className="image-card">
                <h3>Listen on Spotify</h3>
                <div className="image-wrapper image-wrapper-green">
                  <img src={SpotifyIcon} />
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="blue-section">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="svg-wave-top white-fill"
        >
          <path
            fillOpacity="1"
            d="M0,64L48,80C96,96,192,128,288,122.7C384,117,480,75,576,69.3C672,64,768,96,864,117.3C960,139,1056,149,1152,160C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="section-wrapper section-padding-top">
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <br className="break-no-show" />
          <Footer />
        </div>
      </section>
    </div>
  )
}

export default AboutMe
