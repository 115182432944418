import { useState } from 'react'
import scheduleLesson from '../requests/schedule-lesson'

const INPUTS = ['first', 'last', 'phone', 'email', 'message']
const REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const RULES = {
  first: {
    rule: first => first !== '',
    message: 'First Name is required.'
  },
  last: {
    rule: last => last !== '',
    message: 'Last Name is required.'
  },
  email: {
    rule: email => REGEX.test(email),
    message: 'Email must be a valid email.'
  }
}
export const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const handleSubmit = event => {
    setSubmitting(true)
    event.preventDefault()
    const result = {}
    const errors = []
    const formData = new FormData(event.target)
    for (const [key, value] of formData) {
      result[key] = value
      if (RULES[key]) {
        const { rule, message } = RULES[key]
        if (!rule(value)) {
          errors.push(message)
        }
      }
    }
    if (errors.length > 0) {
      window.alert(errors.join(' \n'))
      setSubmitting(false)
    } else {
      INPUTS.forEach(
        formInput => (document.getElementById(formInput).disabled = true)
      )
      scheduleLesson(result)
        .then(response => {
          setSuccess(true)
          window.alert(
            [
              'Thank you for your interest!',
              'We received your message and will contact you shortly.',
              'A confirmation email has been sent to you, should you have additional questions.'
            ].join('\n\n')
          )
        })
        .catch(error => console.log(error))
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-success" />
      <div className="form-row">
        <div className="form-field">
          <label for="first" required>
            First Name *
          </label>
          <input id="first" name="first"></input>
        </div>
        <div className="form-field">
          <label for="last" required>
            Last Name *
          </label>
          <input id="last" name="last"></input>
        </div>
        <div className="form-field flex-one">
          <label for="phone">Phone</label>
          <input id="phone" name="phone"></input>
        </div>
      </div>
      <div className="form-field">
        <label for="email" required>
          Email *
        </label>
        <input id="email" name="email"></input>
      </div>
      <div className="form-field">
        <label for="message">Message</label>
        <textarea
          id="message"
          name="message"
          rows="3"
          placeholder="Tell me about the potential student or provide any questions you may have"
        ></textarea>
      </div>
      <div className="form-button-wrap">
        <button className="button" type="submit" disabled={submitting}>
          {success ? 'Form Submitted' : submitting ? 'Submitting...' : 'Submit'}
        </button>
        {success && (
          <span className="form-success-message">
            We received your inquiry and will contact you shortly!
          </span>
        )}
      </div>
    </form>
  )
}
